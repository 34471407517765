// Given an argument that could be a function, ensure it's a function
const toFunction = (thing) => {
  if (typeof thing === 'function') {
    return thing;
  }

  return () => thing;
};

export default class Intervention {
  /**
   * Construct an intervention object using a couple given defaults.
   * @param {function} defaultTemplate
   * @param {object} defaultContext
   */
  constructor(defaultTemplate, defaultContext = {}) {
    this._template = defaultTemplate;
    this._contextQueue = [];
    this.render = this.render.bind(this);
    this.pushContext(defaultContext);
  }
  /**
   * Accepts a callback that changes the template to be rendered
   * @param {function} selectedTemplate
   */
  setTemplate(selectedTemplate) {
    this._template = selectedTemplate;
  }

  /**
   * Pushes context into a deterministic queue to be reduced into a single object.
   * @param {object} context An object or pending promise to push into the context queue.
   */
  pushContext(context) {
    this._contextQueue.push(context);
  }

  async _reduceContext() {
    // Ensure all promises are resolved in the queue.
    const contextQueue = await Promise.all([...this._contextQueue]);

    // Reduce the queue down to a single object.
    return contextQueue.reduce((accumulator, nextVal) => {
      return { ...accumulator, ...nextVal };
    }, {});
  }

  /**
   * Asynchronously resolve and then render the intervention template.
   * @param {object} additionalContext Additional context to pass to the template function
   */
  async render(additionalContext) {
    // Reduce the context queue down to a single object.
    const reducedContext = await this._reduceContext();

    // Resolve the template and context into two variables.
    const [template, ...contextValues] = await Promise.all([
      // Resolve the template
      toFunction(this._template)(),

      // Resolve every value in this._context.
      ...Object.values(reducedContext).map((val) => toFunction(val)()),
    ]);

    // Take the resolved context and zip it back with the context keys.
    const context = Object.fromEntries(
      Object.keys(reducedContext).map((key, index) => [key, contextValues[index]])
    );

    // Put it all together!
    return template({ ...context, ...additionalContext });
  }
}
