/**
 * Utilities for using custom events
 * @module utils/events
 */

import { debug } from '../constants/url';

/**
 * Dispatch a custom event on a specified element
 *
 * @param  {Node}   elem            The DOM node to fire the event on
 * @param  {Object} event           Custom Event object
 * @return {void}
 * @see    https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent
 */
function customEvent(elem, event) {
  elem.dispatchEvent(event);
}

/**
 * Dispatch a data event on a specified element
 *
 * @param  {Node}   elem            The DOM node to fire the event on
 * @param  {String} typeArg         What the event will be referred to
 * @param  {Object} customEventInit An object of event options
 * @return {void}
 */
export function dataEvent(elem, typeArg, customEventInit) {
  const event = new window.CustomEvent(typeArg, customEventInit);

  customEvent(elem, event);

  //
  // If the debug string includes data,
  // log details to console
  //
  if (debug.includes('data')) {
    /* eslint-disable no-console */
    const { type, detail } = event;
    console.info(`%c ${type}`, styles.event);
    console.table(detail);
  }
}

const styles = {
  event: 'color: red; font-size: 18px',
};
