<div class="c-nudge__spacing-container">
    <a class="c-nudge__icon" aria-label="collapse message" role="button" href="#">
        <svg class="c-nudge__compress-icon">
            <use xlink:href="#icon-ui-chevron-compress"></use>
        </svg>
    </a>

    <div class="c-nudge__nudge-container">
        <span class="c-nudge__count" role="text">{{ cap - viewCount }}</span>
        <span class="c-nudge__notice">{{ countNotice }}</span>
        <span class="c-nudge__login--bordered">
            Already a subscriber?
            {{ component('hippo/components/paywall/nudge-login.html',
            tracking="js-login-click-expanded",
            border=false ) }}
        </span>
    </div>
    <div class="c-nudge__copy-container">
        {% if not first %}<span class="c-nudge__count-mobile" role="text">{{ cap - viewCount }}</span>{% endif %}
        <span class="c-nudge__header-mobile">{{ countNoticeMobile }}</span>
        <p class="c-nudge__header">{{ message }}</p>
        <p class="c-nudge__subheader">{{ subheader }}</p>
    </div>
    <!-- right container for subscribe button and compression button -->
    <div class="c-nudge__subscribe-container">
        {{ component('hippo/components/paywall/nudge-subscribe.html') }}
    </div>
</div>