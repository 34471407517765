/**
 * Helper log module that attaches different instances of Debug
 *
 * @see {@link https://github.com/visionmedia/debug|debug}
 *
 * @module utils/log
 */
import Debug from 'debug';

/**
 * Yo dawg I heard you like Debug Objects.
 *
 * @param {String} namespace The debug namespace
 */
export function Log(namespace) {
  const errorDebug = Debug(namespace);
  errorDebug.color = 'crimson';
  errorDebug.log = console.error.bind(console); // eslint-disable-line no-console

  const warnDebug = Debug(namespace);
  warnDebug.color = 'goldenrod';
  warnDebug.log = console.warn.bind(console); // eslint-disable-line no-console

  const debug = Debug(namespace);
  debug.error = errorDebug;
  debug.warn = warnDebug;

  return debug;
}
