{# This component's container is rendered via javascript as its a conditionally rendered component
#}
<div class="c-non-metered-nudge__container-container">
  <div class="c-non-metered-nudge__container">
    <a
      href="https://accounts.theatlantic.com/products/?source=nonmetered_nudge"
      class="js-non-metered-nudge-link"
    >
      <div class="c-non-metered-nudge__text-container">
        {% if coronaCoverage %}
        <div>
          <p class="c-non-metered-nudge__text c-non-metered-nudge__text--main">
            You’re reading The Atlantic’s free coronavirus coverage.
          </p>
          <p class="c-non-metered-nudge__text c-non-metered-nudge__text--main">
            To support this vital reporting, subscribe today.
          </p>
        </div>
        {% else %}
        <p class="c-non-metered-nudge__text c-non-metered-nudge__text--main">
          Enjoy unlimited access to all of our journalism.
        </p>
        {% endif %}
        <p class="c-non-metered-nudge__text c-non-metered-nudge__text--subscribe">
          {% if coronaCoverage %}See Plans{% else %}Subscribe Now{% endif %}
        </p>
      </div>
      <p class="c-non-metered-nudge__text c-non-metered-nudge__text--mobile">
        {% if coronaCoverage %} Subscribe to support our reporting {% else %} Subscribe for
        unlimited access {% endif %}
      </p>
    </a>
  </div>
  <button class="c-non-metered-nudge__close-icon-button js-non-metered-nudge-close">
    <svg class="c-non-metered-nudge__close-icon "><use xlink:href="#icon-nav-close"></use></svg>
  </button>
</div>
