/**
 * Nunjucks
 * @module utils/nunjucks
 */
import Nunjucks from 'nunjucks/browser/nunjucks-slim';

import { debug } from '../constants/url';
import * as accountsConfig from 'accountsConfig';

import RenderExtension from 'theatlantic-nunjucks-helpers/lib/extensions/render';

const env = new Nunjucks.Environment(null, {
  autoescape: false,
});

env.addGlobal('component', function(tmpl, context) {
  const debug_components = debug;

  return env.render(tmpl, Object.assign({}, this.ctx, context, { debug_components }));
});

env.addGlobal('accounts_url', accountsConfig.url);

env.addExtension('render', new RenderExtension(env, 'hippo/components'));

/**
 * Export render function that abstracts some of the Nunjucks ugliness
 * @param  {String}   tmpl    The template name
 * @param  {Object}   context The context object
 * @return {Promise}          A promise resolved with the rendered template
 */
export function render(tmpl, context, options) {
  options = options || {};
  options.env = options.env || env;
  return new Promise((resolve, reject) => {
    const path = `hippo/components/${tmpl}.html`;

    options.env.render(path, context, (err, res) => {
      if (err) {
        reject(err);
      }
      resolve(res);
    });
  });
}
