{% set final = cap - viewCount == 0 %} {% set first = viewCount == 1 %}
<!-- template for all nudges but last, which is below -->
{% if not final %}
<!-- left container for meter count and copy -->
{{ component('hippo/components/paywall/expanded-mid.html',
    message=message,
    cap=cap,
    viewCount=viewCount,
    countNotice=countNotice,
    countNoticeMobile=countNoticeMobile,
    subheader=subheader) }}
{% endif %}

<!-- final nudge -->
{% if final %} {{ component('hippo/components/paywall/expanded-final.html',
    message=message,
    cap=cap,
    viewCount=viewCount,
    countNotice=countNotice,
    countNoticeMobile=countNoticeMobile,
    subheaderMobile=subheaderMobile,
    subheader=subheader) }}
{% endif %}
