{
  "xxsmall": "(max-width: 374px)",
  "xsmall": "(max-width: 575px)",
  "mobile-medium": "(min-width: 374px)",
  "mobile-large": "(min-width: 414px)",
  "small": "(min-width: 576px)",
  "medium": "(min-width: 784px)",
  "large": "(min-width: 976px)",
  "xlarge": "(min-width: 1200px)",
  "xxlarge": "(min-width: 1376px)",
  "hp-mid": "(min-width: 1100px)",
  "standard": "(min-resolution: 1dppx)",
  "hd": "(min-resolution: 1.5dppx)",
  "retina": "(min-resolution: 2dppx)",
  "legacy-large": "(min-width: 1010px)"
}