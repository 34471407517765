import cookie from 'js-cookie';

export default class Cookie {
  constructor(articleViewsCookie) {
    this.articleViewsCookie = articleViewsCookie;
    this.viewedArticles = cookie.get(this.articleViewsCookie) || '[]';
    this.viewedArticlesArray = JSON.parse(this.viewedArticles);
  }

  /**
   * @return {Number} return number of articles in the array
   */
  get length() {
    return this.viewedArticlesArray.length;
  }
}
