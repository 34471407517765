/**
 * Scroll
 * @module utils/ads/scroll
 */
import { Log } from '../log';

import { isScrollSubscriber } from '../user';
import { disable } from '../../constants/url';
import { script } from '../loaders';

if (disable.indexOf('ads') === -1) {
  const ns = 'atl:scroll';
  const log = Log(ns);

  performance.mark(`${ns}_loaded`);

  /**
   * Creates the global Scroll object and the Scroll.do method for queueing Scroll commands.
   * @see https://developer.scroll.com/commands
   */
  (function(a, c, d, e) {
    if (!a[c]) {
      var b = (a[c] = {});
      b[d] = [];
      b[e] = function(a) {
        b[d].push(a);
      };
    }
  })(window, 'Scroll', '_q', 'do');

  /**
   * Creates the Scroll configuration object.
   * @see https://developer.scroll.com/config
   */
  window.Scroll.config = {
    orientation: 'bottom',
    detected: isScrollSubscriber(),
  };

  performance.mark(`${ns}:requested`);

  script('https://static.scroll.com/js/scroll.js')
    .then(() => performance.mark(`${ns}:loaded`))
    .catch((err) => log.error(err));
}
