/**
 * Using a namespace is better than global pollution 🤮
 * @module utils/namespace
 */
import uuid from 'uuid';

import { Log } from './log';
import { User } from './user';

const ns = 'atl:namespace';
const log = Log(ns);

/**
 * The Atlantic Object
 * @type {Object}
 * @property {String} Atlantic.page_view_id - A new UUID generated every page view
 * @property {Class}  Atlantic.User         - The User object
 */
const Atlantic = Object.defineProperties(
  {},
  {
    page_view_id: {
      value: uuid.v4(),
      writable: false,
    },
    User: {
      value: new User(),
      writable: false,
    },
  }
);

// allow `window.Atlantic` to override any default value set here (if necessary)
window.Atlantic = Object.assign(Atlantic, window.Atlantic);

log(window.Atlantic);
