<div class="c-nudge__spacing-container">
    <!-- right container for subscribe button and compression button -->
    <div class="c-nudge__compressed-link c-nudge__compressed-link--mobile-hidden">
      <span class="c-nudge__message-desktop">{{ messageCompressed }}</span>
      <span class="c-nudge__message-mobile c-nudge__message-mobile--emphasized">{{ countNoticeMobile }}</span>
    </div>
    {{ component('hippo/components/paywall/nudge-login.html',
    tracking="js-login-click-compressed",
    border=true ) }}
    <div class="c-nudge__subscribe-container">
      {{ component('hippo/components/paywall/nudge-icon-expand.html') }}
      <a
        href="{{accounts_url}}/products?source=nudge{{viewCount}}"
        class="c-nudge__subscribe-link-mobile c-nudge__button c-nudge__button--centered"
      >
        Subscribe for unlimited access
      </a>
      <a
        href="{{accounts_url}}/products?source=nudge{{viewCount}}"
        class="c-nudge__button c-nudge__button--mobile-hidden"
      >
        Subscribe Now
      </a>
    </div>
  </div>