<div class="c-nudge__spacing-container">
    <!-- right container for subscribe button and compression button -->
    <div class="c-nudge__compressed-link {% if buttonCentered %} c-nudge__compressed-link--mobile-hidden {% endif %}">
      <span class="c-nudge__message-desktop c-nudge__message-desktop--emphasized">{{ messageCompressed }}</span>
      <span class="c-nudge__message-mobile c-nudge__message-mobile--emphasized">{{ countNoticeMobile }}</span>
    </div>
    {{ component('hippo/components/paywall/nudge-login.html',
    tracking="js-login-click-compressed",
    border=true ) }}
      <div class="c-nudge__subscribe-container">
      {{ component('hippo/components/paywall/nudge-icon-expand.html') }}
      {{ component('hippo/components/paywall/nudge-subscribe.html') }}
    </div>
  </div>