/* eslint-disable no-console */

import renderIntoDom from './utils/renderIntoDom';
import messages from './utils/onboardingMessages';
import dataLayer from '../globals/dataLayer';

const ONBOARDING_STATUS = 'onboarding_status';
const ONBOARDING_GROUP_ID = 'onboarding_group_id';
const ONBOARDING_GROUP_UPDATED = 'onboarding_group_updated';

const renderMessageForGroup = (groupId, cohort) => {
  // Given an action name, push a custom event to GTM.
  const trackEvent = (action) => {
    // Pushing directly into the dataLayer prevents a race condition on initial load.
    dataLayer.push({
      event: 'onboarding_interaction',
      onboarding_action: action,
      onboarding_message: groupId,
      onboarding_cohort: cohort,
    });
  };

  // Attaches the handler for the close button.
  const addCloseHandler = () => {
    const closeButton = document.querySelector('.c-nudge-onboarding__close-icon');
    closeButton.addEventListener('click', () => {
      document.querySelector('.c-nudge-onboarding').remove();
      trackEvent('message active close');
    });
  };

  // Attaches the handler
  const addScrollHandler = () => {
    const handleScroll = () => {
      const collapseOffset = 500;

      if (window.pageYOffset > collapseOffset) {
        const elem = document.querySelector('.c-nudge-onboarding');
        // Make sure the element exists before interacting with it.
        if (elem) {
          elem.classList.add('c-nudge-onboarding--collapsed');
          trackEvent('message passive close');
        }
        document.removeEventListener('scroll', handleScroll);
      }
    };
    document.addEventListener('scroll', handleScroll);
  };

  const addCTAClickHandler = () => {
    const links = document.querySelectorAll('.js-onboarding-link');
    links.forEach((elem) => {
      elem.addEventListener('click', () => trackEvent('message clicked'));
    });
  };

  const message = messages[groupId];
  if (!message) {
    console.error(`There's no message for group ${groupId}`);
    return;
  }

  return renderIntoDom('nudge-onboarding', {
    message,
  })
    .then(addCloseHandler)
    .then(addScrollHandler)
    .then(addCTAClickHandler)
    .then(() => trackEvent('message display'));
};

function init() {
  const profile = window.blueConicClient.profile.getProfile();
  let lastMessageDate;

  // If we've saved a last updated date, pull it from Blueconic.
  if (profile.getValue(ONBOARDING_GROUP_UPDATED)) {
    lastMessageDate = new Date(parseInt(profile.getValue(ONBOARDING_GROUP_UPDATED)));
  } else {
    // Otherwise, pretend the last updated date is 1970.
    lastMessageDate = new Date(0);
  }

  const group_id = parseInt(profile.getValue(ONBOARDING_GROUP_ID), 10) || 0;

  //only show to people with the jwt -- otherwise we'll show nudges
  if (!profile.getValue(ONBOARDING_STATUS)) {
    return;
  }

  // Throttle by 7 days
  const daysSinceLastMessage = (new Date() - lastMessageDate) / 1000 / 60 / 60 / 24;
  if (daysSinceLastMessage < 7) {
    return;
  }

  // Render the message and then update blueconic.
  renderMessageForGroup(group_id, profile.getValue(ONBOARDING_STATUS)).then(() => {
    profile.setValue(ONBOARDING_GROUP_UPDATED, new Date().getTime());
    profile.setValue(ONBOARDING_GROUP_ID, group_id + 1);

    // Save to BC
    window.blueConicClient.profile.updateProfile(() => {
      console.debug('Blueconic saved the latest onboarding messages');
    });
  });
}

const debugGroup = location.search.match(/onboarding_group\=(\d)/);

if (!debugGroup) {
  // If there is no debug url param, load via blueconic targeting.
  window.addEventListener('onBlueConicLoaded', function() {
    // Need to explicitly load some props from BC
    const profile = window.blueConicClient.profile.getProfile();
    profile.loadValues(
      [ONBOARDING_STATUS, ONBOARDING_GROUP_ID, ONBOARDING_GROUP_UPDATED],
      this,
      init
    );
  });
} else {
  // If there is a debug url param, render without all the target checking.
  renderMessageForGroup(debugGroup[1]);
}
