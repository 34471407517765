<div class="c-nudge__spacing-container">
    <a class="c-nudge__icon" aria-label="collapse message" role="button" href="#">
        <svg class="c-nudge__compress-icon">
            <use xlink:href="#icon-ui-chevron-compress"></use>
        </svg>
    </a>

    <div class="c-nudge__nudge-container">
        <span class="c-nudge__notice c-nudge__notice--emphasized">{{ countNotice }}</span>
        <span class="c-nudge__login--bordered">
            Already a subscriber?
            {{ component('hippo/components/paywall/nudge-login.html', tracking="js-login-click-expanded" ) }}
        </span>
    </div>
    <div class="c-nudge__copy-container">
        <span class="c-nudge__header-mobile c-nudge__header-mobile--emphasized">{{ countNoticeMobile }}</span>
        <p class="c-nudge__header">{{ message }}</p>
        <p class="c-nudge__subheader subheader-mobile">{{ subheaderMobile }}</p>
        <p class="c-nudge__subheader subheader-desktop">{{ subheader }}</p>
    </div>
    <!-- right container for subscribe button and compression button -->
    <div class="c-nudge__subscribe-container">
        {{ component('hippo/components/paywall/nudge-subscribe.html') }}
    </div>
</div>