/**
 * API around the user account data cookie.
 *
 * @example
 * import * as user from 'utils/user';
 *
 * @example
 * System.import('utils/user').then(u => log(u));
 *
 * @module utils/user
 */
import cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { Log } from './log';

// we use way too many cookies
import {
  accountData,
  accountOk,
  geolocation,
  scrollSubscriber,
  jwtCookie,
} from '../constants/cookie';

import { userCountry, sessionEnd } from '../constants/endpoints';

const ns = 'atl:user';
const log = Log(ns);

/**
 * The data model for the user profile cookie
 * @typedef {Object} UserCookieObject
 * @property {(Number|null)} af_exp The adfree expiration
 * @property {Number} dt The datetime the cookie was set
 * @property {Array} entitlements The user's list of product entitlements
 * @property {String} fn The user's first name
 * @property {(Number|null)} sv_exp The print expiration date or something
 * @property {String} uuid The user's Janrain uuid
 */

/**
 * Parse the user cookie
 *
 * This could be turned into a generic util, but the cookie library already has
 * a method called [`getJSON`](https://github.com/js-cookie/js-cookie#json)
 *
 * @return {UserCookieObject} The user cookie object
 */
function parseCookie() {
  const cookieVal = cookie.get(accountData);
  if (!cookieVal) {
    return {};
  }
  return JSON.parse(decodeURIComponent(cookieVal));
}

function parseJWT() {
  const token = cookie.get(jwtCookie);
  if (!token) {
    return {};
  }
  return jwtDecode(token);
}

/**
 * Check if the user is a member
 * @return {Boolean} If the user is a member
 */
function isMember() {
  return getProductList().indexOf('masthead') !== -1;
}

/**
 * Determines if the user is Atlantic staff
 */
function isStaff() {
  const { staff } = parseCookie();
  return !!staff;
}

/**
 * Check if the user is a Scroll subscriber
 * @return {Boolean} If the user is a Scroll subscriber
 * @see https://scroll.com/about
 */
export function isScrollSubscriber() {
  return !!cookie.get(scrollSubscriber);
}

/**
 * Check if the user has purchased our adfree experience
 * @return {Boolean} If the user has purchased adfree
 */
function isAdFreeSubscriber() {
  return getProductList().indexOf('adfree') !== -1;
}

/**
 * Check if the user should see ads or not
 * @return {Boolean} If the user should see ads
 */
export function isAdFree() {
  // @TODO: Keep this method but upgrade to support JWT
  return isAdFreeSubscriber() || isScrollSubscriber();
}

/**
 * Check if the user has access past the paywall
 * @return {Boolean} If the user can see beyond the paywall
 */
export function hasPaymeterAccess() {
  return parseJWT().paymeter_access;
}

/**
 * Get the Janrain UUID
 * @return {String} The Janrain UUID
 */
function getJanrainUUID() {
  // @TODO: Remove from GTM's "Data Layer Events" after accounts is replaced.
  const { uuid } = parseCookie();
  return uuid;
}

/**
 * Gets a list of products the user has
 * @return {Array} The product list
 */
export function getProductList() {
  const { entitlements } = parseCookie();
  return entitlements || [];
}

/**
 * Future forward property for the Data team and
 * ad targeting.
 * @return {Array} The array of entitlements (or products)
 */
export function getEntitlementsList() {
  return [
    ...(isLoggedIn() ? ['account'] : ''),
    ...(isStaff() ? ['staff'] : ''),
    ...(parseJWT().paymeter_access ? ['paymeter_access'] : ''),
  ].concat(getProductList());
}

/**
 * Checks if the user is logged in
 * @param  {Boolean} [loggedIn=false] If the use is logged in or not
 */
export function refresh(loggedIn = false) {
  document.documentElement.classList.toggle('user-signed-in', loggedIn);
}

/**
 * Gets the user's geolocation
 *
 * @example
 * import { getGeoLocation } from 'utils/user';
 * getGeoLocation.then(json => log(json));
 *
 * @example
 * System.import('theatlantic/js/utils/user').then((u) => {
 *   u.getGeoLocation().then(json => log(json));
 * });
 *
 * @return {Promise} A promise returning the user geolocation data
 */
export function getGeoLocation() {
  const geoLocation = cookie.get(geolocation);
  if (geoLocation) {
    return Promise.resolve(JSON.parse(geoLocation));
  }
  return fetch(userCountry)
    .then((res) => res.json())
    .catch((e) => log.error(e));
}

/**
 * Remove both login cookies
 */
export function clear() {
  const cookieConfig = {
    domain: '.theatlantic.com',
    path: '/',
  };

  cookie.remove(accountData, cookieConfig);
  cookie.remove(accountOk, cookieConfig);
}

/**
 * Get the user's first name from the cookie
 * @return {String} The users first name
 */
export function getFirstName() {
  // @TODO: delete when Accounts upgrade goes live
  const { fn } = parseCookie();
  return fn;
}

/**
 * Check if the user is logged in
 * @return {Boolean} If the user is logged in
 */
export function isLoggedIn() {
  return (!!cookie.get(accountData) && !!parseCookie()) || !!cookie.get(jwtCookie);
}

/**
 * Sign a user out of Janrain
 *
 * @example
 * import { signOut } from 'utils/user';
 * signOut().then(doSomething);
 *
 * @example
 * System.import('theatlantic/js/utils/user').then((u) => {
 *   u.signOut().then(doSomething);
 * });
 *
 * @return {Promise} A chainable promise so you can do other stuff
 */
export function signOut() {
  const opts = {
    credentials: 'include',
  };
  return fetch(sessionEnd, opts)
    .then(() => {
      clear();
      refresh(false);
    })
    .catch((e) => log(e));
}

/**
 * Instantiate the User object
 */
async function init() {
  const loggedIn = isLoggedIn();

  refresh(loggedIn);
}

/**
 * Class representing a User.
 *
 * This class contains proxied methods and some getters that the data team uses
 * for GTM and other tracking. There are also some public methods exposed to
 * establish parity with some legacy JS in other Ollie apps.
 *
 * @see https://svalbard.atlassian.net/browse/DEV-21831?focusedCommentId=37156&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-37156
 */
export class User {
  /**
   * Create a new User
   */
  constructor() {
    init();
  }

  /**
   * Proxied for the data team
   * @return {Boolean} If the user has a membership
   */
  get hasMembership() {
    return isMember();
  }

  /**
   * Future forward property for the Data team
   * @return {Array} The array of entitlements (or products)
   */
  get entitlements() {
    return getEntitlementsList();
  }

  /**
   * Proxied for the data team
   * @return {String} The Janrain UUID
   */
  getJanrainUUID() {
    return getJanrainUUID();
  }

  /**
   * Proxied for the data team
   * @return {Boolean} If the user will see ads or not
   * @see {@link utils/user#isAdFree}
   */
  isAdFree() {
    return isAdFree();
  }

  /**
   * Proxied for the data team
   * @return {Boolean} If the user is logged in
   * @see {@link utils/user#isLoggedIn}
   */
  isLoggedIn() {
    return isLoggedIn();
  }

  /**
   * Backport for `newsletters.js`
   * @param  {Function} cb The callback
   * @see https://github.com/theatlantic/Atlantic-CMS/blob/develop/apps/newsletters/static/newsletters/js/newsletters.js#L179
   */
  getGeoLocation(cb) {
    getGeoLocation().then((data) => {
      cookie.set(geolocation, data, { expires: 30 });
      cb(data);
    });
  }

  /**
   * Backport for `profiles.js`
   * @return {String} The user's first name
   * @see https://github.com/theatlantic/Atlantic-CMS/blob/develop/apps/profiles/templates/profiles/janrain/profiles.js#L124
   */
  getFirstName() {
    return getFirstName();
  }

  /**
   * Backport for Profiles
   * @see https://github.com/theatlantic/Atlantic-CMS/blob/develop/apps/accounts/static/accounts/main.js#L119
   */
  refresh() {
    return refresh(isLoggedIn());
  }

  /**
   * Backport for Profiles
   * @see https://github.com/theatlantic/Atlantic-CMS/blob/develop/apps/profiles/templates/profiles/janrain/profiles.js#L173
   */
  clear() {
    return clear();
  }
}
