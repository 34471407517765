/**
 * Dynamic content loaders like images and link tags!
 * @module utils/loaders
 */

/**
 * Dynamically load a javascript tag.
 *
 * @example
 * script('some-script.js').then(doStuff);
 *
 * @param  {String}  src The script source
 * @return {Promise}     A promise that resolves/rejects based on script status
 */
export function script(src) {
  return new Promise((resolve, reject) => {
    const el = document.createElement('script');

    el.addEventListener('load', function handleLoad(e) {
      el.removeEventListener('load', handleLoad);
      resolve(e);
    });

    el.addEventListener('error', function handleError(e) {
      el.removeEventListener('error', handleError);
      reject(e);
    });

    el.src = src;
    el.async = true;
    document.body.appendChild(el);
  });
}

/**
 * Dynamically create an image tag. Useful for pixels!
 *
 * @example
 * image('some-url.gif').then(doStuff);
 *
 * @param  {String}  src The image source
 * @return {Promise}     A promise that resolves/rejects based on image status
 */
export function image(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.addEventListener('load', function handleImageLoad(e) {
      img.removeEventListener('load', handleImageLoad);
      resolve(e);
    });

    img.addEventListener('error', function handleImageError(e) {
      img.removeEventListener('error', handleImageError);
      reject(e);
    });

    img.src = src;
  });
}

/**
 * Dynamically create a `link` tag and add it to the DOM
 *
 * @example
 * link({href: 'some-link', as: 'script', rel: 'preload'});
 *
 * @example
 * link({href: 'some-domain', rel: 'dns-prefetch preconnect', crossOrigin: 'anonymous'})
 *
 * @param  {Object} attributes The `link` tag attributes
 */
export function link(attributes) {
  const preloadLink = document.createElement('link');
  Object.keys(attributes).map((attr) => (preloadLink[attr] = attributes[attr]));
  document.head.appendChild(preloadLink);
}
