/**
 *
 * @param  {Object} stickyElement The element to recieve sticky class
 * @param  {Object} stickyContainer Element in which stickyElement will have class
 * @param  {String} stickyClass Name of sticky class to be applied
 * @param  {Number} offsetTop Pixels to be offset from top of page.
 * @param  {Number} offsetBottom Pixels to be offset from bottom of page.
 * @return {void}
 */
export const stickyElements = (
  stickyElement,
  stickyContainer,
  stickyClass,
  offsetTop = 0,
  offsetBottom = 0
) => {
  const startingPosition =
    stickyElement.getBoundingClientRect().top + window.pageYOffset - offsetTop;

  const handleSticky = () => {
    const stoppingPosition = stickyContainer.getBoundingClientRect().bottom;

    if (window.pageYOffset > startingPosition && stoppingPosition >= offsetBottom) {
      stickyElement.classList.add(stickyClass);
    } else {
      stickyElement.classList.remove(stickyClass);
    }
  };

  window.addEventListener('scroll', handleSticky, { passive: true });
};
