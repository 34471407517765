const templates = {
  initial: {
    compressedElem: 'nudge-compressed',
    expandedElem: 'nudge-expanded',
    message: 'Thank you for reading The Atlantic.',
    subheader: 'Enjoy unlimited access to all of our journalism.',
    messageCompressed: 'Enjoy unlimited access to The Atlantic.',
    buttonCentered: true,
    countNotice: 'more free articles this month',
    countNoticeMobile: 'Thank you for reading The Atlantic.',
  },
  mid: {
    compressedElem: 'nudge-compressed',
    expandedElem: 'nudge-expanded',
    message: 'Discover new ideas. Rethink old assumptions.',
    subheader: 'Get unlimited access to The Atlantic.',
    messageCompressed: 'more free articles this month',
    countNotice: 'more free articles this month',
    countNoticeMobile: 'more free articles',
  },
  penultimate: {
    compressedElem: 'nudge-compressed',
    expandedElem: 'nudge-expanded',
    message: 'Discover new ideas. Rethink old assumptions.',
    subheader: 'Get unlimited access to The Atlantic.',
    messageCompressed: 'more free article this month',
    countNotice: 'more free article this month',
    countNoticeMobile: 'more free article',
  },
  last: {
    compressedElem: 'nudge-compressed',
    expandedElem: 'nudge-expanded',
    message: 'Never miss a story from The Atlantic.',
    subheader: 'Get unlimited access to all of our journalism.',
    subheaderMobile: 'Never miss a story. Get unlimited access.',
    messageCompressed: 'This is your last free article.',
    countNotice: 'This is your last free article.',
    countNoticeMobile: 'This is your last free article.',
  },
};

export default function nudgeSequence(pageviews, cap) {
  if (pageviews === 1) {
    return templates.initial;
  } else if (cap - pageviews === 1) {
    return templates.penultimate;
  } else if (pageviews < cap) {
    return templates.mid;
  } else {
    return templates.last;
  }
}
