/**
 * Configuration and extension of the Lazysizes plugin
 *
 * @see {@link https://github.com/aFarkas/lazysizes/|lazysizes}
 *
 * @module utils/lazysizes
 */
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/include/ls.include';

import { token } from 'pageInfo';

const apiComponents = [...document.querySelectorAll('.js-dynamic-content')];

/**
 * Configure lazysizes to use our SystemJS loader for ES6 modules
 */
Object.assign(lazySizes.cfg, {
  systemJs(module, cb) {
    System.import(module).then(cb);
  },
});

/**
 * Bind event listeners to modify our lazyload requests
 * @return {void}
 */
function bindListeners() {
  apiComponents.forEach((component) => {
    component.addEventListener('lazyincludeload', handleLazyLoad);
  });
}

/**
 * Event handler for the 'lazyincludeload' event. Gives us the ability to
 * modify the XMLHttpRequest before it is sent for each lazy loaded item.
 *
 * @param  {Object} event The `event` object
 * @return {void}
 */
function handleLazyLoad(event) {
  if (!event.detail) {
    return;
  }

  event.detail.xhrModifier = modifyRequestForAPI;
}

/**
 * Modifies the XMLHttpRequest that lazysizes sends off to conform to our API
 * needs; specifically adding 'Accept' and 'Authorization' headers.
 *
 * @param  {Object} request   An XMLHttpRequest object
 * @return {void}
 * @see {@link https://github.com/aFarkas/lazysizes/blob/gh-pages/plugins/include/ls.include.js#L314}
 */
function modifyRequestForAPI(request) {
  request.setRequestHeader('Accept', 'application/json');
  request.setRequestHeader('Authorization', `token ${token}`);
}

/**
 * Initialize the module
 */
bindListeners();
lazySizes.init();
