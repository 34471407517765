<div class="c-nudge__spacing-container">
    <!-- right container for subscribe button and compression button -->
    <div class="c-nudge__compressed-link">
      <span class="c-nudge__count" role="text"
        >{{ cap - viewCount }}</span>
      <span class="c-nudge__message-desktop">{{ messageCompressed }}</span>
      <span class="c-nudge__message-mobile">{{ countNoticeMobile }}</span>
    </div>
    {{ component('hippo/components/paywall/nudge-login.html',
    tracking="js-login-click-compressed",
    border=true ) }}
    <div class="c-nudge__subscribe-container">
      {{ component('hippo/components/paywall/nudge-icon-expand.html') }}
      {{ component('hippo/components/paywall/nudge-subscribe.html') }}
    </div>
  </div>