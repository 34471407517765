<div class="c-gate__viewport">
  <!-- begin shadow -->
    <div class="c-gate__shadow"></div>
    <!-- begin gate viewport -->
    <div class="c-gate__spacing-container">
      <span>{{mobileSubtitle}}</span>
      <p class="c-gate__header">{{mobileTitle}}</p>
      <span>{{mobileTitleBelow}}</span>
      <a href="{{ accounts_url }}/products?source=gate" role="link"><div class="c-gate__subscribe-button">See Plans</div></a>
      <span class="c-gate__subscribe-link">Already a subscriber? 
        <a href="{{ accounts_url }}{% if not loggedIn %}/login/{% endif %}" class="c-gate__link {% if not loggedIn %}js-accounts-modal{% endif %}" role="button">
          {% if loggedIn %}
          Link your account
          {% else %}
          Sign in
          {% endif %}
        </a>
      </span>    
    </div>
    <!-- end gate viewport -->
</div>
