/**
 * Cookie specific constants
 * @module constants/cookie
 */

/**
 * The profiles account data cookie name
 * @type {String}
 */
export const accountData = 'acctdata';

/**
 * The profiles account ok cookie name
 * @type {String}
 */
export const accountOk = 'accttok';

/**
 * The profiles membership cookie
 * @type {String}
 */
export const accountMember = 'atlmbr';

/**
 * The geolocation cookie name
 * @type {String}
 */
export const geolocation = 'geolocation';

/**
 * The cookie for Scroll subscribers
 * @type {String}
 */
export const scrollSubscriber = 'scroll0';

/**
 * The cookie for US homepage preference
 * @type {String}
 */
export const preferHpUS = 'show_us_hp';

/**
 * The cookie to track article views
 * @type {String}
 */
export const articleViewsCookie = 'dev_articleviews';

/**
 * The cookie to store the JSON Web Token (JWT)
 * @type {String}
 */
export const jwtCookie = 'atljwt';

/**
 * The cookie to store the JSON Web Token (JWT)
 * @type {String}
 */
export const atlUuidCookie = 'atl_uuid';
