/**
 * Random with min and max.
 * Stolen from underscore.
 *
 * @param      {number}  min     The minimum
 * @param      {number}  max     The maximum
 * @return     {number}  A random number
 */
export function random(min = 0, max = 100) {
  return min + Math.floor(Math.random() * (max - min + 1));
}
