/**
 * Preload urls.
 * @module constants/preload
 */
import { gtm } from 'vendorConfig';

/**
 * A list of urls used for analytics
 * @type {Array}
 */
export const analytics = [
  `https://www.googletagmanager.com/gtm.js?id=${gtm}`,
  'https://www.google-analytics.com/analytics.js',
  'https://www.google-analytics.com/plugins/ua/linkid.js',
];
