/**
 * Widely used global variables and constants
 * @module constants/url
 */
import url from 'url';

/**
 * Convert non array value to array containing that value
 * @param  {String} val The value to convert
 * @return {Array}      The converted array
 */
function toArray(val) {
  if (Array.isArray(val)) {
    return val;
  }
  return [].concat(val).filter((v) => v !== undefined);
}

/**
 * The parsed url of the page
 */
export const parsedUrl = url.parse(window.location.href, true);

/**
 * The debug querystring as an array
 */
export const debug = toArray(parsedUrl.query.debug);

/**
 * The disable querystring as an array
 */
export const disable = toArray(parsedUrl.query.disable);

/**
 * The preview querystring as an array
 */
export const preview = toArray(parsedUrl.query.preview);
