import EventEmitter from 'events';
import Cookie from './Cookie';

export default class Meter {
  constructor({ cap, identifier, cookieName = 'dev_articleviews' }) {
    this.cap = cap;
    this.cookie = new Cookie(cookieName);
    this._events = new EventEmitter();
    this.identifier = identifier;
    this.enabled = false;
  }

  /**
   * Activate the event listener. Trigger `cap-hit` if the user is above the cap, always emit `pageview`.
   */
  enable() {
    this.enabled = true;
    if (this.hasHitCap()) {
      this._emit('cap-hit');
    }
    this._emit('pageview');
  }

  /**
   * Attach an event listener to the Meter emitter. The listener will be removed after the first time the event is called
   * @param {string} event
   * @param {function} callback
   */
  on(event, callback) {
    this._events.once(event, callback);
  }

  /**
   * Emits an event with the correct information passed.
   *
   * @protected
   * @param {string} event Event name that will be emitted.
   * @param {*} identifier Any serializable type to identify the page the event took place on
   */
  _emit(event) {
    this._events.emit(event, {
      identifier: this.identifier,
      viewCount: this.getPageViews(),
      cap: this.getCap(),
    });
  }

  getCap() {
    return this.cap;
  }

  getPageViews() {
    return this.cookie.length;
  }
  /**
   * Returns boolean whether gate criteria has been met
   *
   * @return {boolean}
   */
  hasHitCap() {
    return this.getPageViews() > this.getCap() && this.enabled;
  }
}
