/**
 * Widely used global variables and constants
 * @module lib/globals
 */
import $ from 'jquery';

/**
 * The `window` object
 */
export const $window = $(window);

/**
 * The `document` object
 */
export const $document = $(document);

/**
 * The `html` element
 */
export const $html = $(document.documentElement);

/**
 * The `head` element
 */
export const $head = $('head');

/**
 * The `body` element
 */
export const $body = $('body');

export {
  /**
   * The main jQuery object
   */
  $,
};
