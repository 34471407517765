import dataLayer from '../globals/dataLayer';

export default function meterCallback({ cap, viewCount }) {
  const dataObject = {
    paywallCap: cap,
    paywallViews: viewCount,
  };

  dataLayer.push(dataObject);
}
