import * as pageInfo from 'pageInfo';
import cookie from 'js-cookie';
import renderIntoDom from './utils/renderIntoDom';
import { getDeviceType } from '../utils/device';
import { dataEvent } from '../utils/events';
import dataLayer from '../globals/dataLayer';

export default function nudgeCallback() {
  return async (context) => {
    const getElement = (collapsed) => {
      return collapsed && context.viewCount !== context.cap
        ? context.compressedElem
        : context.expandedElem;
    };

    const dispatchDataEvent = (action) => {
      const collapsed = cookie.get('hasCollapsed') === 'true';
      const nudgeActions = ['nudge:user_expand', 'nudge:user_collapse', 'nudge:scroll_collapse'];
      const event =
        nudgeActions.indexOf(action) === -1 ? 'paywall:interaction' : 'nudge:interaction';

      const detail = {
        action: action,
        label: getElement(collapsed),
        value: context.viewCount,
      };
      dataEvent(window, event, { detail });
    };

    const toggleElement = (element) => {
      const newElement =
        element === context.expandedElem ? context.compressedElem : context.expandedElem;
      return newElement;
    };

    const renderThatElement = (element, context) => {
      renderIntoDom(element, context).then((container) => {
        signInBtnHandlers(element);
        addClickHandler(container, toggleElement(element));
        subscribeBtnHandlers();
      });
    };

    const subscribeBtnHandlers = () => {
      const subscribeBtns = document.querySelectorAll('.c-nudge__button');

      for (let button of subscribeBtns) {
        button.addEventListener('click', () => {
          dispatchDataEvent('nudge:click_subscribe');
        });
      }
    };

    const signInBtnHandlers = (state) => {
      let signIn =
        state === context.expandedElem
          ? document.querySelector('.js-login-click-expanded')
          : document.querySelector('.js-login-click-compressed');

      signIn.addEventListener('click', () => {
        dispatchDataEvent('nudge:click_login');
      });
    };

    const addClickHandler = (container, newElement) => {
      const icon = container.querySelector('.c-nudge__icon');
      icon.addEventListener('click', (e) => {
        e.preventDefault();
        let action =
          newElement === context.expandedElem ? 'nudge:user_expand' : 'nudge:user_collapse';
        dispatchDataEvent(action);
        renderThatElement(newElement, context);
        toggleCookie();
      });
    };

    const toggleCookie = () => {
      const oldCookie = cookie.get('hasCollapsed') === 'true';
      cookie.set('hasCollapsed', !oldCookie);
    };

    const compressCookie = () => {
      cookie.set('hasCollapsed', true);
    };

    //initialize
    const initialize = () => {
      let collapsed = cookie.get('hasCollapsed') === 'true';
      //if breaking news bar is enabled, collapse nudge to avoid blocking too much of mobile screens
      if (document.querySelector('.c-alert')) {
        collapsed = true;
      }
      const template = getElement(collapsed);

      // suppress mobile #ad-boxonly on features-template articles
      const suppressAd = pageInfo.layout === 'feature';

      if (suppressAd) {
        window.ad_queue = window.ad_queue || [];
        window.ad_queue.push(function() {
          document.querySelector('#ad-boxonly').remove();
        });
      }

      renderThatElement(template, context);

      const dataObject = {
        nudgeTemplate: template,
        event: 'Nudge Template Set',
      };
      dataLayer.push(dataObject);
    };

    initialize();

    const calculateSlide = (container) => {
      // height for expanded nudge to slide is container height minus the height of the compressed nudge
      const heightToSlide = `${-(container.offsetHeight - 64)}px`;
      container.classList.add('c-nudge-expanded__container--slide-out');
      container.style.bottom = heightToSlide;

      // add class to the mobile header to face opacity of nudge text
      const desktopContainer = document.querySelector('.c-nudge__nudge-container');
      desktopContainer.classList.add('c-nudge__nudge-container--fade-out');

      const mobileContainer = document.querySelector('.c-nudge__header-mobile');
      mobileContainer && mobileContainer.classList.add('c-nudge__nudge-container--fade-out');

      // add class to the icon to rotate it
      const icon = document.querySelector('.c-nudge__compress-icon');
      icon.classList.add('c-nudge__compress-icon--rotate');
    };

    const handleScroll = () => {
      const deviceType = getDeviceType();
      const isMobile = deviceType === 'mobile';
      const collapseOffset = isMobile ? 100 : 500;
      const expandedContainer = document.querySelector('.c-nudge-expanded__container');

      if (window.pageYOffset > collapseOffset && expandedContainer) {
        // if the nudge is expanded on load, the auto-collapse should have a slide-out effect
        calculateSlide(expandedContainer);
        // listen for slide-out transition (CSS) end, then replace the nudge
        var renderCompressedOnTransitionEnd = function() {
          renderThatElement(context.compressedElem, context);
        };
        expandedContainer.addEventListener('transitionend', renderCompressedOnTransitionEnd);
        expandedContainer.addEventListener('webkitTransitionEnd', renderCompressedOnTransitionEnd);
        compressCookie();
        dispatchDataEvent('nudge:scroll_collapse');
        document.removeEventListener('scroll', handleScroll);
      }
    };

    document.addEventListener('scroll', handleScroll);
  };
}
