import 'hippo/components/non-metered-nudge';
import { render } from '../utils/nunjucks';
import { dataEvent } from '../utils/events';
import dataLayer from '../globals/dataLayer';
import * as pageInfo from 'pageInfo';

/**
 * Communicate data events related to the nonmetered nudge
 * @param {string} action name of the triggered event
 */
const dispatchDataEvent = (action) => {
  const detail = {
    action: action,
    label: 'non-metered nudge',
    value: '',
  };
  dataEvent(window, 'nudge:interaction', { detail });
};

/**
 * Add click listener to the close button
 * @param {element} container container to be closed
 */
const activateCloseButton = (container) => {
  const closeButton = container.getElementsByClassName('js-non-metered-nudge-close')[0];
  closeButton.addEventListener('click', () => {
    dispatchDataEvent('nudge:user_close');
    container.remove();
  });
};

/**
 * Add click listener to the subscribe link
 * @param {element} container element containing link
 */
const activateClickLinkListener = (container) => {
  const clickLink = container.getElementsByClassName('js-non-metered-nudge-link')[0];
  clickLink.addEventListener('click', () => {
    dispatchDataEvent('nudge:click');
  });
};

/**
 * Determine whether nudge is hidden upon render
 * @return {boolean} container container to be closed
 */
const isHidden = () => {
  // nudge is revealed upon switch to slim nav, which requires IntersectionObserver support.
  return pageInfo.primary_channel === 'homepage' && window.IntersectionObserver;
};

const showNonMeteredNudge = (container) => {
  container.classList.add('is-visible');

  const dataObject = {
    nudgeTemplate: 'non-metered nudge',
    event: 'Nudge Template Set',
  };
  dataLayer.push(dataObject);
};

/**
 * Shows hidden nudge
 */
export const handleHiddenNonMeteredNudge = () => {
  const nudge = document.getElementsByClassName('js-non-metered-nudge')[0];
  if (nudge && !nudge.classList.contains('is-visible')) showNonMeteredNudge(nudge);
};

/**
 * Initial render of the nudge
 */
export const renderNonMeteredNudge = async () => {
  const coronaCoverage =
    pageInfo.categories && pageInfo.categories.indexOf('Coronavirus: COVID-19') !== -1;
  const nudgeContainer = document.createElement('div');
  nudgeContainer.className = 'c-non-metered-nudge js-non-metered-nudge';
  nudgeContainer.tabIndex = -1;
  if (!isHidden()) showNonMeteredNudge(nudgeContainer);
  if (pageInfo.primary_channel !== 'homepage')
    nudgeContainer.classList.add('c-non-metered-nudge--non-homepage');
  if (coronaCoverage) nudgeContainer.classList.add('c-non-metered-nudge--article');
  nudgeContainer.innerHTML = await render('non-metered-nudge', { coronaCoverage: coronaCoverage });
  document.body.appendChild(nudgeContainer);
  activateClickLinkListener(nudgeContainer);
  activateCloseButton(nudgeContainer);
};
