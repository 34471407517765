/**
 * Device stuff
 * @module utils/device
 */
import breakpoints from '../constants/breakpoints.json!';

/**
 * Get the device type based on current media query
 * @function
 * @return {String} The device type
 */
export function getDeviceType() {
  let size = 'mobile';
  if (window.matchMedia(breakpoints['xlarge']).matches) {
    size = 'desktop';
  } else if (window.matchMedia(breakpoints['large']).matches) {
    size = 'desktop';
  } else if (window.matchMedia(breakpoints['medium']).matches) {
    size = 'tablet';
  } else if (window.matchMedia(breakpoints['small']).matches) {
    size = 'tablet';
  }
  return size;
}

/**
 * Get the orientation of the device
 * @function
 * @return {String} The orientation
 */
export function getDeviceOrientation() {
  let orientation = 'portrait';
  if (window.matchMedia('(orientation: landscape)').matches) {
    orientation = 'landscape';
  }
  return orientation;
}
