import renderIntoDom from './utils/renderIntoDom';
import dataLayer from '../globals/dataLayer';
import 'hippo/components/gate';

export default function gateCallback() {
  return async (context) => {
    document.body.classList.add('with-gate');

    renderIntoDom('gate', context).then(() => {
      if (window.Atlantic.page_info.request.experiment === 'button-color.blue') {
        const button = document.querySelector('.c-gate__subscribe-button');
        button.classList.add('c-gate__subscribe-button--variant');
      }
    });

    dataLayer.push({
      gateTemplate: 'gate',
      event: 'Gate Template Set',
    });
  };
}
