/**
 * Bundled analytics module
 *
 * @module utils/analytics
 */
import { gtm } from 'vendorConfig';

import { link, script } from './loaders';
import { Log } from './log';
import { disable } from '../constants/url';
import { analytics as analyticsPreload } from '../constants/preload';
import { ab_test_group } from './ads';
import dataLayer from '../globals/dataLayer';
import cookie from 'js-cookie';

import * as pageInfo from 'pageInfo';

const ns = 'atl:analytics';
const log = Log(ns);

performance.mark(`${ns}_loaded`);

/**
 * Allow analytics to be disabled through a querystring or if being viewed
 * through a preview url
 * @type {Boolean}
 */
const isEnabled = disable.indexOf('analytics') === -1;

/**
 * Load GTM if not flagged as disabled
 */
function loadGTM() {
  log('loadGTM');

  dataLayer.push({
    'gtm.start': Date.now(),
    event: 'gtm.js',
  });

  // Push AB test information into GA
  dataLayer.push({
    abt_experiment: pageInfo.request.experiment || null,
    abt_value: cookie.get('atltestbucketv2') || null,
  });

  // Data used to track ad experiments
  dataLayer.push({
    // Leftpad the string, per Evan's request
    ads_ab_testing_bucket: ('000' + ab_test_group).slice(-3),
  });

  const ns = 'gtm';
  performance.mark(`${ns}:requested`);

  script(`https://www.googletagmanager.com/gtm.js?id=${gtm}`)
    .then(() => performance.mark(`${ns}:loaded`))
    .catch((err) => log.error(err));
}

if (isEnabled) {
  analyticsPreload.map((href) =>
    link({
      href,
      as: 'script',
      rel: 'preload',
    })
  );

  link({
    href: 'https://ampcid.google.com',
    rel: 'dns-prefetch preconnect',
  });

  loadGTM();
}
