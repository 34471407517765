/**
 * Determines if a browser supports position: sticky
 */

export function positionStickySupport() {
  const el = document.createElement('div'),
    mStyle = el.style;
  mStyle.cssText = 'position:sticky;position:-webkit-sticky;position:-ms-sticky;';
  return mStyle.position.indexOf('sticky') !== -1;
}
