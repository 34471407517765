<div class="c-nudge-onboarding">
  <div class="c-nudge-onboarding__text-container">
      <svg class="c-nudge-onboarding__icon" width="24" height="24">
          <use xlink:href="#icon-onboarding-{{message.icon}}"></use>
      </svg>
    <div class="c-nudge-onboarding__text">{{ message.desktop }}</div>
    <a class="c-nudge-onboarding__link--desktop js-onboarding-link" href="{{message.linkUrl}}">
      {{ message.link }}
    </a>
    <div class="c-nudge-onboarding__container-mobile">
      <a href="{{message.linkUrl}}" class="c-nudge-onboarding__link--mobile js-onboarding-link" >
          {{ message.mobile }}
      </a>
    </div>
  </div>
  <svg class="c-nudge-onboarding__close-icon">
      <use xlink:href="#icon-nav-close"></use>
  </svg>
</div>
