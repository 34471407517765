import 'hippo/components/nudge-expanded';
import 'hippo/components/nudge-compressed';
import 'hippo/components/nudge-onboarding';
import 'hippo/components/paywall/nudge-login';
import 'hippo/components/paywall/nudge-icon-expand';
import 'hippo/components/paywall/nudge-subscribe';
import 'hippo/components/paywall/compressed-final';
import 'hippo/components/paywall/compressed-first';
import 'hippo/components/paywall/compressed-mid';
import 'hippo/components/paywall/expanded-final';
import 'hippo/components/paywall/expanded-mid';
import { isLoggedIn } from '../../utils/user';
import { render } from '../../utils/nunjucks';

const containerSelectors =
  '.c-article-header, .c-article-header--ideas, .article-body, .article-container';

export default async function renderIntoDom(element, context) {
  const loggedIn = isLoggedIn();
  //find old ones and delete them
  const oldContainer = document.querySelector('.c-nudge__container');
  oldContainer ? oldContainer.remove() : null;
  //render a new one in
  const nudgeContainer = document.createElement('div');
  nudgeContainer.className = `c-nudge__container c-${element}__container`;
  nudgeContainer.tabIndex = -1;
  nudgeContainer.innerHTML = await render(element, { ...context, loggedIn });
  const articleContainer = document.querySelector(containerSelectors);
  articleContainer.append(nudgeContainer);

  return nudgeContainer;
}
