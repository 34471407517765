/**
 * bottom persistent ad for the new article page
 * @module components/bottom-persistent-ad
 */
import { disable } from '../constants/url';

import { Log } from '../utils/log';
import { once } from '../utils/helpers';
import { getDeviceType } from '../utils/device';

const ns = 'atl:bottom-persistent-ad';
const log = Log(ns);

const hiddenModifier = 'c-bottom-persistent-ad--hidden';

/**
 * Initialize the bottom persistent ad that we all love so much 😍
 */
function initBottomPersistent() {
  log('initBottomPersistent');

  const bottomPersistentWrapper = document.querySelector('.js-bottom-persistent-ad');
  if (!bottomPersistentWrapper) {
    return; // Component is not present.
  }

  const bottomPersistent = bottomPersistentWrapper.querySelector('.js-bottom-persistent');
  const close = bottomPersistentWrapper.querySelector('.js-bottom-persistent-ad__button');

  function closeBottomPersistent() {
    log('close bottom persistent?');
    requestAnimationFrame(() => {
      bottomPersistentWrapper.classList.add(hiddenModifier);
    });
  }

  function showBottomPersistent() {
    log('getting to show bottom persistent?');
    requestAnimationFrame(() => {
      bottomPersistentWrapper.classList.remove(hiddenModifier);
    });
  }

  function removeDeferred() {
    log('removing deferred attribute from bottomPersistent');
    bottomPersistent.removeAttribute('defer');
  }

  once(window, 'scroll', removeDeferred);

  // Don't defer the ad on desktop. We're able do
  // this on mobile by increasing the prebid timeout,
  // which would not make sense on bigger screens.
  if (getDeviceType() === 'desktop') {
    removeDeferred();
  }

  bottomPersistent.addEventListener('ad-loaded', showBottomPersistent);
  bottomPersistent.addEventListener('viewable-impression', () => {
    setTimeout(() => {
      log('are we getting here?!');
      once(window, 'scroll', closeBottomPersistent);
    }, 10000);
  });

  if (close) {
    close.addEventListener('click', closeBottomPersistent);
  }
}

if (disable.indexOf('bottom-persistent-ad') === -1) {
  initBottomPersistent();
}
