/**
 * Our ad code
 * @module utils/ads
 */
import 'theatlantic/js/components/bottom-persistent-ad';

import 'theatlantic/scss/components/ad.scss';
import 'theatlantic/scss/components/bottom-persistent-ad.scss';

import './ads/scroll';

import * as adsConfig from 'adsConfig';

import { ads as adsPreconnect } from '../constants/preconnect';
import { disable } from '../constants/url';

import { Log } from './log';
import { random } from './random';
import { link } from './loaders';
import { getEntitlementsList } from './user';
import { meter } from '../paywall/paywall';
import * as pageInfo from 'pageInfo';

const ns = 'atl:ads';
const log = Log(ns);

performance.mark(`${ns}_loaded`);

export const ab_test_group = random(1, 100);

/**
 * Load ads.js
 */
async function loadAds() {
  const config = Object.assign({}, adsConfig);

  // There's no ad config... 👋
  if (Object.keys(config).length === 0) {
    return;
  }

  // Preload third party deps while ads.js is loading.
  adsPreconnect.map((item) =>
    link({
      href: item,
      rel: 'preconnect dns-prefetch',
      crossOrigin: 'anonymous',
    })
  );

  HACK_protect_rail_height_balance(config.globals.nopromo);

  try {
    config.globals.user = getEntitlementsList();
    config.globals.abt = ab_test_group;

    const { Controller } = await System.import('adsjs');
    new Controller(adsConfig);
  } catch (e) {
    log.error(e);
    await System.import('theatlantic/js/components/house-creative-fallback');
  }
}

// Only disable ads for people are who about to see the paywall
// 1. Has seen enough articles to get the gate
// 2. Nudges are enabled
// 3. User isn't logged in as a paying user
const paywallDisableAds =
  meter.hasHitCap() &&
  pageInfo.show_nudges &&
  getEntitlementsList().indexOf('paymeter_access') === -1;

if (disable.indexOf('ads') === -1 && !paywallDisableAds) {
  loadAds();
}

/**
 * On article pages (that have a 'blah' sectioned structure)
 * the right rail can become taller than the first section and
 * create trapped whitespace.
 *
 * Prevent this by switching the ad to 300x250 only on short
 * top sectioned articles.
 */
function HACK_protect_rail_height_balance(nopromo) {
  const firstBlah = document.querySelector('.blah');
  if (!firstBlah) {
    return;
  }

  const content = firstBlah.querySelector('.l-article__container__container');
  const adSizeSet = firstBlah.querySelector('.l-rail gpt-sizeset[sizes="rail"]');
  let { height } = content.getBoundingClientRect();

  // If we will serve a house ad, we can expect it will get a little taller
  if (!nopromo) {
    height += 150;
  }

  // This ad may only be a box
  if (height < 1020) {
    adSizeSet.setAttribute('sizes', '[[300, 250]]');
  }
}
