<!-- left container for meter count and copy -->
{% set final = cap - viewCount == 0 %}
{% set first = viewCount == 1 %}
<!-- all nudges render from this template except the final one, which is below -->
{% if not final and not first %}
{{ component('hippo/components/paywall/compressed-mid.html',
    message=message,
    cap=cap,
    viewCount=viewCount,
    countNotice=countNotice,
    countNoticeMobile=countNoticeMobile,
    subheader=subheader,
    messageCompressed=messageCompressed) }}
{% endif %}

<!-- final nudge displays different elems -->
{% if final %}
{{ component('hippo/components/paywall/compressed-final.html',
    message=message,
    cap=cap,
    viewCount=viewCount,
    countNotice=countNotice,
    countNoticeMobile=countNoticeMobile,
    messageCompressed=messageCompressed,
    subheader=subheader) }}
{% endif %}

<!-- first nudge displays different elems -->
{% if first %}
{{ component('hippo/components/paywall/compressed-first.html',
    message=message,
    cap=cap,
    viewCount=viewCount,
    countNotice=countNotice,
    countNoticeMobile=countNoticeMobile,
    messageCompressed=messageCompressed,
    subheader=subheader) }}
{% endif %}
