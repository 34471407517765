/**
 * Endpoints and what not
 * @example
 * import { endpoints } from 'endpoints';
 * @module utils/endpoints
 */
import { Log } from './log';
import { relatedArticles, mostPopular, video } from '../constants/endpoints';

const log = Log('atl:endpoints');

/**
 * Class the exposes updatable endpoints
 */
class Endpoints {
  /**
   * The constructor
   */
  constructor() {
    this.relatedArticles = relatedArticles;
    this.mostPopular = mostPopular;
    this.video = video;
  }

  /**
   * Get an endpoint
   * @example
   * endpoints.get('relatedArticles');
   * // returns '/path/to/related/articles/'
   *
   * @param  {String} endpoint The desired endpoint
   * @return {String}          The endpoint
   */
  get(endpoint) {
    return this[endpoint];
  }

  /**
   * Set an endpoints value
   * @example
   * endpoints.set('relatedArticles', '/path/to/ab/test/endpoint');
   * @param {String} endpoint The endpoint to update
   * @param {String} value    The value to set the endpoint to
   */
  set(endpoint, value = '') {
    if (value === '') {
      log.warn('No `value` set for endpoint');
    }

    this[endpoint] = value;
  }
}

/**
 * An instantiated endpoints module
 * @type {Endpoints}
 */
export const endpoints = new Endpoints();
