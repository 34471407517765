export default [
  {
    desktop: 'Thank you for subscribing—now, discover an Atlantic newsletter for you.',
    link: 'See All',
    linkUrl: 'https://accounts.theatlantic.com/accounts/newsletters/',
    mobile: 'Discover an Atlantic newsletter for you.',
    icon: 'newsletter',
  },
  {
    desktop: 'Open a world of ideas, with our iOS app for subscribers.',
    link: 'Get The Atlantic App',
    linkUrl: 'https://apps.apple.com/us/app/atlantic-magazine-digital/id397599894',
    mobile: 'Explore our new iOS app for subscribers.',
    icon: 'app',
  },
  {
    desktop: 'Just a reminder: You can play a new crossword, every weekday.',
    link: 'Start Solving',
    linkUrl: 'https://www.theatlantic.com/free-daily-crossword-puzzle/',
    mobile: 'Solve a new crossword, every weekday.',
    icon: 'crossword',
  },
  {
    desktop: 'Add nuance to your News Feed.',
    link: 'Follow The Atlantic on Facebook',
    linkUrl: 'https://www.facebook.com/TheAtlantic',
    mobile: 'Never miss a story. Follow us on Facebook.',
    icon: 'facebook',
  },
];
