/**
 * Preconnect/dns-prefetch critical hosts.
 * @module constants/preconnect
 */

/**
 * A list of vendor hosts used in ads.js
 * @type {Array}
 */
export const ads = [
  'https://z.moatads.com',
  'https://www.googletagservices.com',
  'https://c.amazon-adsystem.com',
];
