/**
 * Generic endpoints and what nots
 * @module constants/endpoints
 */
import { article_id as articleId } from 'pageInfo';

const apiEndpoint = 'api/2.0/articles';

/**
 * The endpoint to look up related articles
 * @type {String}
 */
export const relatedArticles = `/${apiEndpoint}/${articleId}/related-articles/`;

/**
 * The endpoint to look up most popular
 * @type {String}
 */
export const mostPopular = `/${apiEndpoint}/?section=most-popular`;

/**
 * The endpoint to look up video
 * @type {String}
 */
export const video = `/${apiEndpoint}/?section=video`;

/**
 * The endpoint to look up a user's country by IP
 * @type {String}
 */
export const userCountry = '/api/user_country/';

/**
 * The endpoint to sign up a user for newsletters
 * @type {String}
 */
export const newsletterSignUp = 'https://accounts-api.theatlantic.com/api/v1/newsletters/sign-up/';
