/**
 * A collection of links for sharing content
 * @module components/share-social
 */
import { stickyElements } from '../utils/sticky';
import { copyLink } from '../utils/copy-link';

const shareKit = document.getElementsByClassName('js-share-social-sticky')[0];

if (shareKit) {
  const articleContainer = document.querySelector('.js-article-container');
  const copyLinkContainer = shareKit.querySelector('.js-copy-link-container');
  const buttonArray = [...shareKit.querySelectorAll('.js-share-social__link')];
  const nav = document.querySelector('.js-navigation');
  // account for height of absolute pos sticky nav in offset calculation
  const offsetTop = window.innerHeight / 2 - nav.getBoundingClientRect().height;
  const offsetBottom = window.innerHeight / 2 + shareKit.getBoundingClientRect().height;

  stickyElements(shareKit, articleContainer, 'c-share-social--sticky', offsetTop, offsetBottom);

  copyLink('.js-copy-link-btn', copyLinkContainer, 'c-share-social--success');

  buttonArray.map((button) => button.addEventListener('click', handlePopup));
}
/**
 * Opens fb/email share window in a modal
 *
 * @param  {Object} event The `event` object
 * @return {void}
 */
function handlePopup(event) {
  event.preventDefault();

  const button = event.currentTarget;
  const { height = 600, width = 400 } = button.dataset;
  const top = window.innerHeight / 2 - height / 2;
  const left = window.innerWidth / 2 - width / 2;

  window.open(
    button.href,
    'shareWindow',
    `height=${height}, width=${width}, top=${top}, left=${left}, resizeable=1`
  );
}
