/**
 * A collection of useful helper functions
 * @module utils/helpers
 */
const ns = 'atl:helpers';

performance.mark(`${ns}_loaded`);

/**
 * A helper function for running an event handler once,
 * similar to jQuery's `.one()`
 * @param {Element}  el    The element to attach the handler to
 * @param {String}   type  The event to listen for
 * @param {Function} cb    Function to run once on the event
 * @return {void}
 */
export function once(el, type, cb) {
  const eventHandler = (e) => {
    el.removeEventListener(e.type, eventHandler);
    return cb.call(el, e);
  };

  if (el) {
    el.addEventListener(type, eventHandler);
  }
}
