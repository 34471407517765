/**
 * Opens a modal over the page with a login screen iframed in and (re)sizes it
 * correctly. Listens for success and close postMessage events.
 */
import iFrameResizer from 'iframe-resizer';
import 'hippo/components/accounts-modal';
import { render } from '../utils/nunjucks';

const messageCallback = ({ message }) => {
  switch (message.type) {
    case 'SUCCESS':
      location.reload();
      break;

    case 'CLOSE':
      closeModal();
      break;
  }
};

const openModal = async () => {
  const modalElem = document.createElement('div');
  modalElem.className = 'c-accounts-modal';
  modalElem.innerHTML = await render('accounts-modal');
  document.body.append(modalElem);
  iFrameResizer.iframeResizer(
    {
      log: false,
      scrolling: 'omit',
      messageCallback,
    },
    '#accounts-modal'
  );
};

const closeModal = () => {
  const modalElem = document.querySelector('.c-accounts-modal');
  modalElem.remove();
};

document.addEventListener('click', (e) => {
  if (e.target && e.target.classList.contains('js-accounts-modal')) {
    e.preventDefault();
    openModal();
  }
});
