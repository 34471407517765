import ClipboardJS from 'clipboard';

/**
 *
 * @param  {String} instantiationClass Class of the element to be clicked
 * @param  {Object} successContainer Container to display success state
 * @param  {Object} successClass Class to be placed on the successContainer
 * @return {void}
 */
export const copyLink = (instantiationClass, successContainer, successClass) => {
  const copyButton = document.querySelector(instantiationClass);

  new ClipboardJS(instantiationClass);

  const handleClick = () => {
    successContainer.classList.add(successClass);
    window.setTimeout(() => {
      successContainer.classList.remove(successClass);
    }, 1500);
  };
  copyButton.addEventListener('click', handleClick);
};
