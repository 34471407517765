import { positionStickySupport } from '../../utils/sticky-support';
import { handleHiddenNonMeteredNudge } from '../../paywall/non-metered-nudge';

//elements & classes for hamburger click event
const navHiddenClass = 'u-hidden';
const overlayHiddenClass = 'is-hidden';
const activeClass = 'is-active';
const expandedClass = 'is-expanded';
const menuIcon = document.getElementById('hamburger-menu');
const hamburger = document.getElementById('hamburger-icon');
const navigation = document.getElementById('main-navigation--lacroix');
const navList = document.getElementById('nav-list');
const overlay = document.querySelector('.js-overlay');
const expandedNav = document.getElementById('expanded-nav');

// runs events to open the hidden nav menu
const openNav = (expandedNavContainer) => {
  menuIcon.setAttribute('aria-expanded', true);
  document.body.classList.add('js-nav-menu-open');
  overlay.classList.remove(overlayHiddenClass);
  expandedNavContainer.classList.remove(navHiddenClass);
  navigation.classList.add(expandedClass);
  navList.classList.add(expandedClass);
  hamburger.classList.add(activeClass);
  document.body.addEventListener('click', handleBodyClick);
};

// listens for clicks on the body while the nav is expanded
const handleBodyClick = (e) => {
  if (e.target.classList.contains('js-overlay')) {
    closeNav(expandedNav);
  }
};

// runs events to close the nav menu
const closeNav = (expandedNavContainer) => {
  menuIcon.setAttribute('aria-expanded', false);
  document.body.classList.remove('js-nav-menu-open');
  overlay.classList.add(overlayHiddenClass);
  expandedNavContainer.classList.add(navHiddenClass);
  navigation.classList.remove(expandedClass);
  navList.classList.remove(expandedClass);
  hamburger.classList.remove(activeClass);
  document.body.removeEventListener('click', handleBodyClick);
};

menuIcon.addEventListener('click', () => {
  let isHidden = expandedNav.classList.contains(navHiddenClass);
  if (isHidden) {
    openNav(expandedNav);
  } else {
    closeNav(expandedNav);
  }
});

/* add intersection observer to slide lefthand nav elements into slim nav on scroll */
const supportsSticky = positionStickySupport();
const bigA = document.getElementById('big-a');
const menuItems = document.getElementById('nav-list');
const hamburgerMenu = document.querySelector('.c-expanded-nav');
const navLines = document.querySelector('.c-nav__before');
const smallA = document.getElementById('small-a');
const isHomepage =
  Atlantic.page_info.view === 'homepage' || Atlantic.page_info.view === 'international_homepage';

// if it's NOT a legacy browser (aka IE 11), remove the 'legacy' class
if (supportsSticky) {
  navigation.classList.remove('legacy');
}

// if the cineflex ad is present, we need position the lines on the big A nav differently
const cineflexAdjustment = () => {
  let navLinesPositioned = navLines.classList.contains('c-nav__before--cineflex');
  if (navLinesPositioned) {
    navLines.classList.remove('c-nav__before--cineflex');
  } else {
    navLines.classList.add('c-nav__before--cineflex');
  }
};

// set up the intersection observer
const observeNav = () => {
  let observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        let isMobile = window.innerWidth >= 576;
        let cineflexAd = false;

        if (entry.isIntersecting && isMobile) {
          cineflexAd = document.querySelector('.js-cineflex-ad').classList.contains('ad-loaded');
          menuItems.classList.remove('c-nav__list--menu--sticky');
          hamburgerMenu.classList.remove('is-slim');
          smallA.classList.remove('c-nav__item--small-a--displayed');
          if (cineflexAd) {
            cineflexAdjustment();
          }
        } else if (isMobile) {
          cineflexAd = document.querySelector('.js-cineflex-ad').classList.contains('ad-loaded');
          menuItems.classList.add('c-nav__list--menu--sticky');
          hamburgerMenu.classList.add('is-slim');
          smallA.classList.add('c-nav__item--small-a--displayed');
          if (cineflexAd) {
            cineflexAdjustment();
          }
        }
        if (!entry.isIntersecting) handleHiddenNonMeteredNudge();
      });
    },
    { rootMargin: '0px 0px 0px 0px' }
  );

  observer.observe(bigA);
};

// run the observer only if it's the homepage and supports both sticky and intersectionObserver
if (supportsSticky && isHomepage && window.IntersectionObserver) {
  observeNav();
}
