import * as pageInfo from 'pageInfo';
import Meter from './utils/Meter';
import Intervention from './utils/Intervention';
import nudgeCallback from './nudge';
import gateCallback from './gate';
import meterCallback from './meter';
import { renderNonMeteredNudge } from './non-metered-nudge';
import fromEntries from 'fromEntries';
import nudgeSequence from './utils/nudgeSequence';
import { hasPaymeterAccess } from '../utils/user';

fromEntries.shim();

const articleViewLimit = 3;

export const meter = new Meter({
  //this would be true if someone was in another test -- how to check just against the experiment and not the variant?
  cap: articleViewLimit,
  identifier: pageInfo.article_id,
  cookieName: 'articleViews',
});

export const nudge = new Intervention(
  nudgeCallback,
  nudgeSequence(meter.getPageViews(), meter.getCap())
);

export const gate = new Intervention(gateCallback, {
  mobileSubtitle: "We hope you've enjoyed your free articles.",
  mobileTitle: "Don't miss another story. Become a subscriber today.",
  title: "We hope you've enjoyed your free articles. To keep reading, subscribe today.",
  mobileTitleBelow: 'Subscribers enjoy unlimited access.',
});

meter.on('pageview', meterCallback);

const urlParams = new URLSearchParams(window.location.search);

// If the user isn't a subscriber, render the nudges and the gate.
if (!hasPaymeterAccess()) {
  if (urlParams.get('surface') === 'meter_limit_reached') {
    meter.on('pageview', gate.render);
  } else if (pageInfo.show_nudges) {
    //standard nudges is logged in
    if (meter.getPageViews() <= meter.getCap()) {
      meter.on('pageview', nudge.render);
    }
    //then gate!
    meter.on('cap-hit', gate.render);
  }
}

if (pageInfo.has_meter) {
  meter.enable();
}

if (!pageInfo.has_meter && !hasPaymeterAccess() && pageInfo.title !== 'Newsletters')
  renderNonMeteredNudge();
